import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export function Edit(props) {
  const { id } = useParams();
  const [Sku, setSku] = useState(1);
  const [Estado, setEstado] = useState('0');
  const [Coleccion, setColeccion] = useState(193);
  const [Referencia, setReferencia] = useState(74);
  const [Color, setColor] = useState(193);
  const [Accesorio, setAccesorio] = useState(1);
  const [Precio, setPrecio] = useState(0);
  const [Garantia, setGarantia] = useState(1);
  const [Existencia, setExistencia] = useState(0);
  const [ProductsDetail, setProductsDetail] = useState([]);
  const [Loading, setLoading] = useState(true);
  const urlBase = '/Inventario/';
  const actionGetById = 'GetById/';
  const urlProductsDetail = 'Inventario/GetProductDetail';

  useEffect(() => {
    const abortController = new AbortController();
    async function fetchData() {
      if (Loading) {
        await fetchProductsDetail();
        const url = `${process.env.REACT_APP_API_URL}${urlBase}${actionGetById}${id}`;
        debugger;
        console.log(url);
        const response = await axios(url);
        console.log(response);
        const dataTemp = response.data.Data;
        setSku(dataTemp.SkuId);
        setEstado(dataTemp.Estado);
        setReferencia(dataTemp.ProductoId);
        setColeccion(dataTemp.ColeccionId);
        setColor(dataTemp.CualidadId);
        setAccesorio(dataTemp.AccesorioId);
        setPrecio(dataTemp.Precio);
        setGarantia(dataTemp.GarantiaId);
        setExistencia(dataTemp.Existencia);
        setLoading(false);
      }
    }

    fetchData();
    return () => {
      abortController.abort();
    };
  }, [Loading, id]);

  async function fetchProductsDetail() {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/${urlProductsDetail}`,
    );
    const dtoResponse = await response.json();
    setProductsDetail(dtoResponse.Data);
    // setLoading(false);
    console.log(ProductsDetail);
  }

  const _handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      skuId: parseInt(Sku),
      estado: Estado,
      productoId: parseInt(Referencia),
      cualidadId: parseInt(Color),
      coleccionId: parseInt(Coleccion),
      accesorioId: parseInt(Accesorio),
      garantiaId: parseInt(Garantia),
      existencia: parseFloat(Existencia),
      precio: parseFloat(Precio),
    };

    console.log(JSON.stringify(body));

    fetch(`${process.env.REACT_APP_API_URL}${urlBase}${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .catch((error) => console.error('Error:', error))
      .then((response) => {
        console.log(response.ok);

        if (response.ok) {
          alert('Haz Editado correctamente el producto.');
          props.history.push('/Producto/Index');
        }
      });
  };

  function handleBack(e) {
    e.preventDefault();
    props.history.push('/Producto/Index');
  }

  return (
    <>
      {Loading ? (
        <span>Cargando</span>
      ) : (
        <form className="container">
          <br />
          <br />
          <div className="form-row">
            <div className="col">
              <label>Sku</label>
              <select
                className="form-control"
                onChange={(e) => setSku(e.target.value)}
                value={Sku}
              >
                {ProductsDetail.Sku.map((row) => (
                  <option value={row.Id}>{row.NomSku}</option>
                ))}
              </select>
            </div>
            <div className="col">
              <label>Producto</label>
              <select
                className="form-control"
                onChange={(e) => setReferencia(e.target.value)}
                value={Referencia}
              >
                {ProductsDetail.Producto.map((row) => (
                  <option value={row.Id}>{row.NomProducto}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row mt-3">
            <div className="col">
              <label>Coleccion</label>
              <select
                className="form-control"
                onChange={(e) => setColeccion(e.target.value)}
                value={Coleccion}
              >
                {ProductsDetail.Coleccion.map((row) => (
                  <option value={row.Id}>{row.NomColeccion}</option>
                ))}
              </select>
            </div>
            <div className="col">
              <label>Color</label>
              <select
                className="form-control"
                onChange={(e) => setColor(e.target.value)}
                value={Color}
              >
                {ProductsDetail.Cualidad.map((row) => (
                  <option value={row.Id}>{row.NomCualidad}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row mt-3">
            <div className="col">
              <label>Garantia</label>
              <select
                className="form-control"
                onChange={(e) => setGarantia(e.target.value)}
                value={Garantia}
              >
                {ProductsDetail.Garantia.map((row) => (
                  <option value={row.Id}>{row.NomGarantia}</option>
                ))}
              </select>
            </div>
            <div className="col">
              <label>Accesorios</label>
              <select
                className="form-control"
                onChange={(e) => setAccesorio(e.target.value)}
                value={Accesorio}
              >
                {ProductsDetail.Accesorio.map((row) => (
                  <option value={row.Id}>{row.NomAccesorio}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-row mt-3">
            <div className="col">
              <label>Existencia</label>
              <input
                className="form-control"
                type="text"
                placeholder="Existencia"
                value={Existencia}
                onChange={(e) => setExistencia(e.target.value)}
              />
            </div>
            <div className="col">
              <label>Precio</label>
              <input
                className="form-control"
                type="number"
                placeholder="Precio"
                value={Precio}
                onChange={(e) => setPrecio(e.target.value)}
              />
            </div>
            <div className="col">
              <label>Estado</label>
              <input
                className="form-control"
                type="text"
                placeholder="Estado"
                value={Estado}
                onChange={(e) => setEstado(e.target.value)}
              />
            </div>
          </div>
          <div className="form-row mt-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => _handleSubmit(e)}
            >
              Guardar
            </button>
            <button
              type="button"
              className="btn btn-link"
              onClick={(e) => handleBack(e)}
            >
              Volver
            </button>
          </div>
        </form>
      )}
    </>
  );
}
