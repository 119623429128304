import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export const CustomNav = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand href="/">
        <img
          src={`${window.location.origin}/logo.png`}
          width="100"
          height="90"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <NavDropdown title="Productos" id="collasible-nav-dropdown">
            <NavDropdown.Item>
              <Link className="text-xs-center" to={`/producto/Create`}>
                Crear producto
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link className="text-xs-center" to={`/producto/Index`}>
                Lista de Productos
              </Link>
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
