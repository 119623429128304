import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function Create(props) {
  const classes = useStyles();
  const [Sku, setSku] = useState(1);
  const [Estado, setEstado] = useState("0");
  const [Coleccion, setColeccion] = useState(193);
  const [Referencia, setReferencia] = useState(74);
  const [Color, setColor] = useState(193);
  const [Accesorio, setAccesorio] = useState(1);
  const [Precio, setPrecio] = useState(0);
  const [Garantia, setGarantia] = useState(1);
  const [Existencia, setExistencia] = useState(0);
  const [ProductsDetail, setProductsDetail] = useState([]);
  const [Loading, setLoading] = useState(true);
  const urlBase = "/Inventario/";
  const urlProductsDetail = "Inventario/GetProductDetail";

  useEffect(() => {
    if (Loading) {
      fetchData();
    }
  });

  async function fetchData() {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/${urlProductsDetail}`
    );
    let dtoResponse = await response.json();
    console.log(dtoResponse.Data);
    setProductsDetail(dtoResponse.Data);
    console.log(ProductsDetail);
    setLoading(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const body = {
      skuId: parseInt(Sku),
      estado: Estado,
      productoId: parseInt(Referencia),
      cualidadId: parseInt(Color),
      coleccionId: parseInt(Coleccion),
      accesorioId: parseInt(Accesorio),
      garantiaId: parseInt(Garantia),
      existencia: parseFloat(Existencia),
      precio: parseFloat(Precio),
    };

    console.log(JSON.stringify(body));

    fetch(`${process.env.REACT_APP_API_URL}${urlBase}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .catch((error) => console.error("Error:", error))
      .then((response) => {
        console.log(response.ok);

        if (response.ok) {
          alert("Haz Editado correctamente el producto.");
          props.history.push(`/Producto/Index`);
        }
      });
  }

  return (
    <>
      {Loading ? (
        <span>Cargando</span>
      ) : (
        <form className="container">
          <br />
          <br />
          <div class="form-row">
            <div class="col">
              <label>Sku</label>
              <select
                class="form-control"
                onChange={(e) => setSku(e.target.value)}
                value={Sku}
              >
                {ProductsDetail.Sku.map((row) => (
                  <option value={row.Id}>{row.NomSku}</option>
                ))}
              </select>
            </div>
            <div class="col">
              <label>Producto</label>
              <select
                class="form-control"
                onChange={(e) => setReferencia(e.target.value)}
                value={Referencia}
              >
                {ProductsDetail.Producto.map((row) => (
                  <option value={row.Id}>{row.NomProducto}</option>
                ))}
              </select>
            </div>
          </div>
          <div class="form-row mt-3">
            <div class="col">
              <label>Coleccion</label>
              <select
                class="form-control"
                onChange={(e) => setColeccion(e.target.value)}
                value={Coleccion}
              >
                {ProductsDetail.Coleccion.map((row) => (
                  <option value={row.Id}>{row.NomColeccion}</option>
                ))}
              </select>
            </div>
            <div class="col">
              <label>Color</label>
              <select
                class="form-control"
                onChange={(e) => setColor(e.target.value)}
                value={Color}
              >
                {ProductsDetail.Cualidad.map((row) => (
                  <option value={row.Id}>{row.NomCualidad}</option>
                ))}
              </select>
            </div>
          </div>
          <div class="form-row mt-3">
            <div class="col">
              <label>Garantia</label>
              <select
                class="form-control"
                onChange={(e) => setGarantia(e.target.value)}
                value={Garantia}
              >
                {ProductsDetail.Garantia.map((row) => (
                  <option value={row.Id}>{row.NomGarantia}</option>
                ))}
              </select>
            </div>
            <div class="col">
              <label>Accesorios</label>
              <select
                class="form-control"
                onChange={(e) => setAccesorio(e.target.value)}
                value={Accesorio}
              >
                {ProductsDetail.Accesorio.map((row) => (
                  <option value={row.Id}>{row.NomAccesorio}</option>
                ))}
              </select>
            </div>
          </div>
          <div class="form-row mt-3">
            <div class="col">
              <label>Existencia</label>
              <input
                class="form-control"
                type="text"
                placeholder="Existencia"
                value={Existencia}
                onChange={(e) => setExistencia(e.target.value)}
              />
            </div>
            <div class="col">
              <label>Precio</label>
              <input
                class="form-control"
                type="number"
                placeholder="Precio"
                value={Precio}
                onChange={(e) => setPrecio(e.target.value)}
              />
            </div>
            <div class="col">
              <label>Estado</label>
              <input
                class="form-control"
                type="text"
                placeholder="Estado"
                value={Estado}
                onChange={(e) => setEstado(e.target.value)}
              />
            </div>
          </div>
          <div class="form-row mt-3">
            <button
              type="button"
              class="btn btn-primary"
              onClick={(e) => handleSubmit(e)}
            >
              Guardar
            </button>
            <button type="button" class="btn btn-link">
              Volver
            </button>
          </div>
        </form>
      )}
    </>
  );
}
