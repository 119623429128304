import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

export function Photos(props) {
  const { id } = useParams();
  const [file, setfile] = useState({});
  const [data, setData] = useState(null);
  const [Loading, setLoading] = useState(true);
  const url = `${process.env.REACT_APP_API_URL}/skuphotos/`;
  const pathAzure = "https://glasses.blob.core.windows.net/filesopticapp/";

  useEffect(() => {
    const abortController = new AbortController();
    async function fetchData() {
      let response = await axios(url + id);
      setData(response.data.data);
      setLoading(false);
    }
    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  async function submit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("idProduct", "250584");
    console.log(file);
    if (file) {
      for (const photo of file) {
        formData.append("photos", photo);
      }
    }

    await fetch(url + id, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .catch((error) => console.error("Error:", error))
      .then((response) => {
        console.log(response);

        if (response.ok) {
          alert("Haz subido la foto.");
          setLoading(true);
        } else {
          alert("Algo anda mal.");
        }
      });
  }

  function setFile(e) {
    setfile(e.target.files);
  }

  function deletePhoto(e, id) {
    e.preventDefault();
    axios.delete(url + id).then((res) => {
      if (res.data.data) {
        alert("Eliminaste correctamente la foto.");
        setLoading(true);
      } else {
        alert("Algo anda mal.");
      }
    });
  }
  return (
    <>
      {Loading ? (
        <span> Cargando... </span>
      ) : (
        <form onSubmit={(e) => submit(e)} className="container">
          <br />
          <br />
          <br />
          <div className="row">
            {data ? (
              data.map((row) => (
                <div
                  key={row.id}
                  className="card col-md-4"
                  style={{ width: 400 }}
                >
                  {row.type === "Image" || row.type === "image" ? (
                    <img
                      className="card-img-top"
                      src={pathAzure + row.namePhoto}
                    />
                  ) : (
                    <video
                      src={pathAzure + row.namePhoto}
                      autoPlay
                      muted
                      loop
                      style={{ maxHeight: 400 }}
                    ></video>
                  )}

                  <div className="card-body">
                    <h4 className="card-title"></h4>
                  </div>
                  <div className="card-footer text-muted">
                    <div className="form-group form-check">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={(e) => deletePhoto(e, row.id)}
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-md-4">
                <label> No hay fotos, subelas.</label>
              </div>
            )}
          </div>

          <input
            name="files"
            type="file"
            className="btn btn-light"
            accept="image/*, video/*"
            onChange={(e) => setFile(e)}
            multiple
          />
          <br />
          <br />
          <button className="btn btn-primary mt-3" type="submit">
            Guardar
          </button>
          <br />
          <br />
        </form>
      )}
    </>
  );
}
