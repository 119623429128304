import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export function Index() {
  const [data, setData] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    async function doSomething() {
      if (Loading) {
        let response = await fetch(
          `${process.env.REACT_APP_API_URL}/Inventario`
        );

        let rta = await response.json();
        setData(rta.Data);
        setDataFiltered(rta.Data);
        setLoading(false);
      }
    }
    doSomething();
  }, []);

  const _handleSubmitDelete = (e, id) => {
    e.preventDefault();

    //     var rta = window.confirm("Seguro que deseas eliminar este producto?");

    //     if (rta) {
    //       fetch(`${process.env.REACT_APP_API_URL}/Productos/${id}`, {
    //         method: "DELETE",
    //         headers: {
    //           Accept: "application/json",
    //           "Content-Type": "application/json",
    //         },
    //       })
    //         .catch((error) => console.error("Error:", error))
    //         .then((response) => {
    //           console.log(response.ok);

    //           if (response.ok) {
    //             alert("Haz Eliminado correctamente el producto.");
    //             props.history.push(`/Producto/Create`);
    //             //window.location.reload(true);
    //           }
    //         });
    //     }
  };

  const handleBuscar = (e) => {
    setDataFiltered(
      data.filter((p) =>
        p.NomCompleto.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <div className="container">
      <br />
      {Loading ? (
        <span>Cargando...</span>
      ) : (
        <>
          <input
            type="text"
            placeholder="Buscar"
            onChange={(e) => handleBuscar(e)}
          />
          <table className="table table-striped" aria-labelledby="tabelLabel">
            <thead>
              <tr>
                <th>Sku</th>
                <th>Producto</th>
                <th>Color</th>
                <th>Accesorios</th>
                <th>Garantia</th>
                <th>Estado</th>
                <th>Existencia</th>
                <th>Precio</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {dataFiltered.map((row) => (
                <tr key={row.Id}>
                  <td>{row.Sku}</td>
                  <td>{row.Referencia}</td>
                  <td>{row.Color}</td>
                  <td>{row.Accesorio}</td>
                  <td>{row.Garantia}</td>
                  <td>{row.Estado}</td>
                  <td>{row.Existencia}</td>
                  <td>{row.Precio}</td>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <Link className="text-xs-center" to={`./Edit/${row.Id}`}>
                        Editar
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        className="text-xs-center"
                        to={`./UploadPhotos/${row.SkuId}`}
                      >
                        Fotos
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <button
                        className="btn btn-danger"
                        onClick={(e) => _handleSubmitDelete(e, row.Id)}
                      >
                        Eliminar
                      </button>
                    </li>
                  </ul>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
