import React from "react";
import { Route, Switch } from "react-router-dom";
import { Create as CreateProduct } from "./views/producto/Create";
import { Index as IndexProduct } from "./views/producto/Index";
import { Edit as ProductEdit } from "./views/producto/Edit";
import { Photos as UploadPhotos } from "./views/producto/Multimedia";
import App from "./App";

const AppRoutes = () => {
  return (
    <App>
      <Switch>
        <Route exact path="/Producto/Create" component={CreateProduct} />
        <Route exact path="/Producto/Index" component={IndexProduct} />
        <Route path="/Producto/Edit/:id" component={ProductEdit} />
        <Route path="/Producto/UploadPhotos/:id" component={UploadPhotos} />
      </Switch>
    </App>
  );
};

export default AppRoutes;
